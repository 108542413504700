@use '~styles/colors';
@use '~styles/common';

.questionHeader {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  width: 100%;
}

.requiredIndication {
  color: colors.$red-required-color;
  margin-left: 5px;
}

.conditionalHeading {
  display: flex;
}

.description {
  margin-bottom: 16px;
}
