@use '~styles/colors';
@use '~styles/common';
@use '~styles/fontSizes';

.showingOriginal {
  color: colors.$info-color;
  display: flex;
  font-size: fontSizes.$font-small;
  justify-content: space-between;
  margin: 4px 0;

  > span {
    margin-right: 4px;
  }
}

.overrideQuestionWrapper {
  display: flex;
  flex-direction: column;

  .formWrapper {
    display: flex;
    flex-direction: column;
  }

  .overrideText {
    @extend %form-control;
  }

  .overrideDescription {
    @extend %form-control;

    margin-top: 12px;
    min-height: 80px;
    resize: vertical;
  }

  .actionsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 27.5px;

    .rightArea {
      align-items: center;
      display: flex;
      flex-direction: row;
    }
  }
}

.aspect {
  color: colors.$info-color;

  &::after {
    border-left: 1px solid colors.$light-gray-color;
    content: '';
    margin: 0 24px;
  }
}

.childrenWrapper {
  margin: 25.5px 0;
}
