/* stylelint-disable */
.demographicsWidget {
  gap: 24px;
}
.demographicGroup {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 236px;
}
.demographicLabel {
  font-weight: 600;
}
