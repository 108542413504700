@use '~styles/colors';
@use '~styles/common';

.header {
  background-color: colors.$white-color;
  box-shadow: 0 1px 0 var(--light-gray);
  display: flex;
  flex-direction: column;
  height: common.$generic-header-height;
  justify-content: space-between;
  padding: 24px 28px;

  &.backLinkHeader {
    height: common.$generic-header-height-with-backlink;
  }

  .backLink {
    text-decoration: none;
  }

  .headerText {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .actions {
    column-gap: 16px;
    display: flex;
  }

  .description {
    margin-block-start: 6px;
  }
}
