.logicJumpForm {
  display: grid;
  grid-column-gap: 27px;
  grid-row-gap: 21px;
  grid-template-columns: 0.2fr 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  margin: 16px auto;

  &.noActions {
    grid-template-rows: repeat(3, 1fr);
  }

  .originCondition {
    grid-area: 1 / 1 / 2 / 2;
  }
  .questionText {
    grid-area: 1 / 2 / 2 / 4;
  }
  .conditionOperator {
    grid-area: 2 / 2 / 3 / 3;
  }
  .conditionValue {
    grid-area: 2 / 3 / 3 / 4;
  }
  .destinationText {
    grid-area: 3 / 1 / 4 / 2;
  }
  .actionOperator {
    grid-area: 3 / 2 / 4 / 3;
  }
  .destinationChoice {
    grid-area: 3 / 3 / 4 / 4;
  }
  .saveEditButton {
    grid-area: 4 / 1 / 5 / 4;
  }
  .deleteButton {
    grid-area: 4 / 2 / 5 / 4;
  }
}
