@use '@zencity/common-ui/lib/zcd-colors';

.textArea {
  border: 1px solid zcd-colors.$zcd-gray-40;
  border-radius: 4px;
  min-height: 52px;
  overflow-y: auto;
  padding: 7.5px 16px;
  resize: vertical;
  width: 100%;

  &.disabled {
    background-color: zcd-colors.$zcd-gray-20;
    color: zcd-colors.$zcd-gray-80;
  }
}
