@use '@zencity/common-ui/lib/zcd-colors';
@use '~styles/common';

.newScreenForm {
  background-color: zcd-colors.$zcd-gray-10;
  overflow: auto;
  padding: 20px 24px;
}

.screenDescription {
  @extend %form-control;

  margin-top: 12px;
  min-height: 80px;
  resize: vertical;
  width: 100%;
}

.footer {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
}

.fullWidth {
  width: 100%;
}
