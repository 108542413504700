@use '@zencity/common-ui/lib/zcd-colors';

.headerWrapper {
  font-weight: initial;

  > p {
    color: zcd-colors.$zcd-gray-80;
    line-height: 21px;
    margin-top: 4px;
  }
}

.linksContainer {
  margin-block-start: 24px;
}

// stylelint-disable property-no-unknown
:export {
  zcdRed90: zcd-colors.$zcd-red-90;
}
