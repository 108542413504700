@use '~styles/colors';

.languageSelect {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;

  .selectContainer {
    padding-inline-end: 24px;
    width: 100%;
  }
}
